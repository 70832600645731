<!-- 招生系统基础配置 -- 中考考试科目管理 -- 新增 -->
<template>
	<edit-template class="form" @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(()=>$router.back()).catch(()=>{});" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
		<el-form ref="form" :model="form" :rules="rules" label-width="150rem" style="width: calc(100% - 24rem);margin-left:  22rem;margin-bottom: 100rem;min-height: calc(100vh - 412rem)">
      <el-form-item label="报名学校" prop="school_id">
        <el-select v-model="form.school_id" placeholder="请选择">
          <el-option v-for="item in schoolOptions" :label="item.school_name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
			<div style="display: flex; justify-content: left">
				<el-form-item label="考试区域" prop="province" style="width: 260rem">
					<el-select v-model="form.province" :rules="[{ required: true, message: '请选择省份', trigger: 'change' }]" placeholder="请选择" @change="onProvinceChange">
						<el-option v-for="item in provinceOptions" :key="item.address_code" :label="item.address_name" :value="item.address_code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="city" style="width: 260rem">
					<el-select v-model="form.city" :rules="[{ required: true, message: '请选择城市', trigger: 'change' }]" placeholder="请选择" @change="onCityChange">
						<el-option v-for="item in cityOptions" :key="item.address_code" :label="item.address_name" :value="item.address_code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="area">
					<el-select v-model="form.area" :rules="[{ required: true, message: '请选择区/县', trigger: 'change' }]" placeholder="请选择" @change="monitoringAcquisition">
						<el-option v-for="item in areaOptions" :key="item.address_code" :label="item.address_name" :value="item.address_code"></el-option>
					</el-select>
				</el-form-item>
			</div>
      <el-form-item label="档位名称" prop="rank_name">
        <el-select v-model="form.rank_name" placeholder="请选择">
          <el-option v-for="(item) in examinationOptions" :label="item" :value="item "></el-option>
        </el-select>
      </el-form-item>

			<el-form-item label="考试年份" prop="year">
				<el-select v-model="form.year" placeholder="请选择" @change="monitoringAcquisition">
					<el-option :label="(new Date().getFullYear() - 2).toString()" :value="(new Date().getFullYear() - 2).toString()" disabled=""></el-option>
					<el-option :label="(new Date().getFullYear() - 1).toString()" :value="(new Date().getFullYear() - 1).toString()" disabled=""></el-option>
					<el-option :label="new Date().getFullYear().toString()" :value="new Date().getFullYear().toString()"></el-option>
					<el-option :label="(new Date().getFullYear() + 1).toString()" :value="(new Date().getFullYear() + 1).toString()" disabled=""></el-option>
					<el-option :label="(new Date().getFullYear() + 2).toString()" :value="(new Date().getFullYear() + 2).toString()" disabled=""></el-option>
				</el-select>
			</el-form-item>
			<!--			<el-form-item label="优惠商品" prop="goods_id">-->
			<!--				<el-select v-model="form.goods_id" placeholder="请选择">-->
			<!--					<el-option v-for="item in goodsOptions" :label="item.goods_name" :value="item.id"></el-option>-->
			<!--				</el-select>-->
			<!--			</el-form-item>-->

			<el-form-item label="用户分类" prop="type">
				<el-radio v-model="form.type" label="1" @change="typeChange('type')">正式报名结尾款用户</el-radio>
				<el-radio v-model="form.type" label="2" @change="typeChange('type')">未参加预报名直接报名用户</el-radio>
			</el-form-item>

			<h4 style="padding: 50rem 0 20rem 30rem">活动优惠</h4>

			<div v-for="(item, index) in form.amount" :key="index" class="knowledge">
				<div class="flex" style="background: #f4f4f4;padding: 20rem">
					<el-form-item label="开始时间" label-width="80rem" style="margin-bottom: 0">
						<el-date-picker v-model="item.start_at" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间" label-width="80rem" style="margin-bottom: 0">
						<el-date-picker v-model="item.end_at" format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="优惠金额" label-width="80rem" style="margin-bottom: 0">
						<el-input-number v-model="item.amount" :max="1000000000" :min="0" :precision="2" :step="100" style="display: flex;flex: 1"></el-input-number>
					</el-form-item>
				</div>
				<div class="flex" style="margin-left: 40rem">
					<el-button v-if="form.amount.length !== 1" circle="" icon="el-icon-delete" @click="deleteKnowledge(index)"></el-button>
					<el-button circle="" icon="el-icon-plus" @click="addKnowledge"></el-button>
				</div>
			</div>
		</el-form>
		
	
</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
		return {
			form: {
				province: '',
				city: '',
				area: '',
				school_id: '',
				year: new Date().getFullYear() + '',
				goods_id: '',
				rank_name: '',
				type: '',
				amount: [{start_at: '', end_at: '', amount: ''}],
			},
			rules: {
				province: [{required: true, message: "请选择省份"}],
				city: [{required: true, message: "请选择城市"}],
				area: [{required: true, message: "请选择区/县"}],
				school_id: [{required: true, message: "请选择校区"}],
				year: [{required: true, message: "请选择考试年份"}],
				goods_id: [{required: true, message: "请选择优惠商品"}],
				rank_name: [{required: true, message: "请选择档位"}],
				type: [{required: true, message: "请选择用户分类", trigger: 'change'}],
			},
			provinceOptions: [],
			cityOptions: [],
			areaOptions: [],
			subjectOptions: [],
			schoolOptions: [],
			goodsOptions: [],
			examinationOptions: [],
		}
	},
	created() {
		this.getProvince()
		this.getSubject()
		// 获取学校
		this.$_register('/api/recruit/common/consulting-school').then(res => {
			this.schoolOptions = res.data.data.entity_school
		})
		// 获取商品
		this.$_register('/api/recruit/common/goods').then(res => {
			this.goodsOptions = res.data.data
		})
		this.$_register.get('api/recruit/examination-rank/get-rank').then(res => {
			this.examinationOptions = res.data.data
		})
	},
	// watch: {
	// 	form: {
	// 		deep: true,
	// 		handler(val) {
	// 			// if (val.province && val.city && val.area && val.year) {
	// 			// 	this.form.examination_rank_id = ''
	// 			// 	this.$_register.get('/api/recruit/scholarship/rank', {params: {province: val.province, city: val.city, area: val.area, year: val.year}}).then(res => {
	// 			// 		this.examinationOptions = res.data.data
	// 			// 	})
	// 			// }
	// 		}
	// 	}
	// },
	methods: {
		typeChange(fieldName){
			this.$refs.form.validateField(fieldName, ()=>{});
		},
		getSubject() {
			this.$_register('/api/recruit/examination/subject').then(res => {
				let data = res.data.data
				if (data.length) {
					this.subjectOptions = data.map(item => {
						return {
							...item,
							disabled: false
						}
					})
				}
			})
		},

		// 添加科目
		addKnowledge() {
			this.form.amount.push({start_at: '', end_at: '', amount: ''})
		},

		//获取省份
		getProvince() {
			this.$_register('/api/recruit/common/area', {params: {address_code: '000000'}}).then(res => {
				// console.log(res)
				this.provinceOptions = res.data.data
			})
		},

		//获取城市
		getCity(e) {
			this.cityOptions = []
			this.form.city = ''
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.cityOptions = res.data.data
			})
		},

		//获取区/县
		getArea(e) {
			this.areaOptions = []
			this.form.area = ''
			this.$_register('/api/recruit/common/area', {params: {address_code: e}}).then(res => {
				this.areaOptions = res.data.data
			})
		},

		// 省份变更
		onProvinceChange(val) {
			this.getCity(val)
			this.monitoringAcquisition()
		},

		// 城市变更
		onCityChange(val) {
			this.getArea(val)
			this.monitoringAcquisition()
		},

		// 监听档位信息获取
		monitoringAcquisition() {
			// this.form.examination_rank_id = ''
			// this.examinationOptions = []
			// if (this.form.province && this.form.city && this.form.area && this.form.year) {
			// 	this.$_register.get('/api/recruit/scholarship/rank', {params: {province: this.form.province, city: this.form.city, area: this.form.area, year: this.form.year}}).then(res => {
			// 		this.examinationOptions = res.data.data
			// 	})
			// }
		},

		// 删除科目
		deleteKnowledge(index) {
			this.form.amount.splice(index, 1)
		},

		// 提交数据
		submit() {
			this.$refs.form.validate((res) => {
				if (res) {
					let params = {...this.form}
					this.$_register.post("/api/recruit/scholarship/store", params).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg,
							onClose: () => {
								this.$store.commit("setPage", 1);
								this.$router.back();
							}
						});
					})
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>

.form {
	padding-top: 24rem;
}

::v-deep .el-form .el-input {
	min-width: 250rem;
}

.knowledge {
	margin-top: 24rem;
	display: flex;
	align-items: center;

	.knowledge_box {
		display: flex;
		padding-top: 12rem;
		height: 60rem;
		background-color: #f4f4f4;
	}

	.add_knowledge {
		display: flex;
	}
}

.el-input-number {
	min-width: 250rem;
}
</style>
